/* Layout */

.section {
  margin: 2rem 0;
  padding: 2rem 0;
}

.section header {
  margin-bottom: 2.5rem;
}

.section:last-of-type {
  margin-bottom: -2rem;
}

@media(max-width: 768px) {
  .section header {
    margin-bottom: 1.5rem;
  }
}

/* Typography */

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #2F2F2D;
}

h1 {
  font-weight: 700;
  font-size: 2.5rem;
}

p {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@media(max-width: 768px) {
	p {
    font-size: 1.25rem;
    line-height: 2rem;
	}
}

/* Theme */

.section-default {
  background-color: #ffffff;
}

.section-inverted {
  background-color: #f5f5f5;
}

a {
  color: #469BFC;
}

a.btn-primary {
  background-color: #469BFC;
  border-color: #469BFC;
}

/* Components */

.btns-social {
  margin: -32px;
}

.btns-social > .btn {
  width: 64px;
  height: 64px;
  font-size: 2rem;

  margin: 32px;
}

.btns-social > .btn > i {
  /* vertical-align: middle; */
}